import styled from "styled-components"

import { AxiosError } from "axios"

import { AdjustableLocationMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import {
  formatPostalAddress,
  formatStreetname,
} from "src/components/Homes/HomeDetails/Overview/addressUtils"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { ILocation } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { IconButton } from "src/ui/Button/IconButton"
import PenSvg from "src/ui/icons/pen-outlined.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function StepHomeGroupBuildingConfirm({
  address,
  location,
  onEdit,
  error,
}: {
  address: THomeGroup["address"]
  location?: ILocation
  onEdit: () => void
  error?: AxiosError | null
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.summary)}
      </MText>

      <MCard border boxShadow={false}>
        <AdjustableLocationMap
          location={location}
          showAdjustButton={false}
          height="200px"
        />

        <Deets>
          <Title>
            <MText variant="subtitle" marginBottom={spacing.XS}>
              {t(langKeys.building_details)}
            </MText>
            <IconButton Icon={PenSvg} variant="minimal" onClick={onEdit} />
          </Title>

          {address && <MText variant="body">{formatStreetname(address)}</MText>}
          {address && (
            <MText variant="body">{formatPostalAddress(address)}</MText>
          )}
        </Deets>
      </MCard>

      {!!error && (
        <Alert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </Alert>
      )}
    </div>
  )
}

const Alert = styled(MBanner)`
  margin-top: ${spacing.M};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Deets = styled.div`
  margin-top: ${spacing.M};
`
