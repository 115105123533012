import { useState } from "react"
import styled from "styled-components"

import { GooglePlacesAutoComplete } from "src/components/Homes/GooglePlacesAutoComplete"
import { extractAddressData } from "src/data/homeAddress/logic/homeAddressDataUtils"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { Heading2Mixin } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export const CREATE_HOMEGROUP_FORM_ID = "create-homegroup-form"

export function HomeGroupNameStep({
  homeGroupName,
  setHomeGroupName,
  setHomeGroupAddress,
  isError,
  onSubmit,
}: {
  homeGroupName: string
  setHomeGroupName: (name: string) => void
  setHomeGroupAddress: (address: THomeGroup["address"]) => void
  isError: boolean
  onSubmit?: (options: { manualAddress: boolean }) => void
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()

  const [addressSearchString, setAddressSearchString] = useState("")
  const [useManualAddress, setUseManualAddress] = useState(false)

  function getAddress({
    address,
    addressComponents,
  }: {
    address: string
    addressComponents: google.maps.GeocoderAddressComponent[] | null
  }) {
    if (!addressComponents) {
      setAddressSearchString("")
      return
    }
    setAddressSearchString(address)
    const { street_name2: _x, ...extractedAddress } =
      extractAddressData(addressComponents)

    setHomeGroupAddress(extractedAddress)
  }

  return (
    <FormBox
      id={CREATE_HOMEGROUP_FORM_ID}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit?.({ manualAddress: useManualAddress })
      }}
    >
      <Title>
        {t(langKeys.homegroup_building_wizard_name_step_title, {
          name: org.name,
        })}
      </Title>

      <MTextField
        label={t(langKeys.name)}
        value={homeGroupName}
        onChange={(value) => setHomeGroupName(value)}
        required
      />

      <AutocompleteSection>
        <GooglePlacesAutoComplete
          inputValue={addressSearchString}
          setInputValue={setAddressSearchString}
          onLocationSelected={getAddress}
          label={`${t(langKeys.address)}*`}
          required
        />

        <ManualAddressButton
          type="submit"
          form={CREATE_HOMEGROUP_FORM_ID}
          onClick={() => {
            setUseManualAddress(true)
            setAddressSearchString("")
            onSubmit?.({ manualAddress: true })
          }}
        >
          {t(langKeys.create_home_enter_address_manually)}
        </ManualAddressButton>
      </AutocompleteSection>

      {isError && (
        <AlertBox type="error">
          {t(langKeys.failed_something_went_wrong)}
        </AlertBox>
      )}
    </FormBox>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const Title = styled.div`
  ${Heading2Mixin};
`

const AlertBox = styled(MBanner)`
  margin-top: ${spacing.M};
`
const ManualAddressButton = styled(TextButton)`
  justify-self: start;
`

const AutocompleteSection = styled.div<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "grid")};
  gap: ${spacing.S};
`
